.form-action {
  /* background-color: #f5f5f5; */
  background-color: transparent;
  margin: 1rem 0 0;
  padding: 0.5rem 1rem;
}

.bg-widget-transparent {
  /* background-color: rgba(51, 51, 51, 0.3) !important; */
}

.position-relative {
  position: relative !important;
}

.input-select {
  height: 44px !important;
  color: #f8f8f8 !important;
  /* padding: 10px 16px !important; */
}

.input-select-transparent {
  min-height: 44px !important;
  border: none;
  color: #f8f8f8 !important;
}

.comfirm_modal {
  background: #1f0019;
}
.comfirm_modal .styles_closeButton__20ID4 {
  background-color: #fe3a25;
}
.comfirm_modal_opacity {
  background: rgba(255, 255, 255, 0.7);
}
.comfirm_modal h3 {
  text-align: center;
  color: #fff;
}
.comfirm_modal h5 {
  text-align: center;
  color: #fff;
}

.css-vj8t7z {
  /* border: none !important;
  background-color: rgba(51, 51, 51, 0.425) !important;
  color: #fff !important; */
}

.select__menu {
  color: #1f0019;
}

.margintop {
  margin-top: 230px;
}
.css-xp4uvy {
  /* color: #f8f8f8 !important; */
}

.css-bl6clz,
.css-2o5izw {
  /* border: none !important;
  background-color: rgba(51, 51, 51, 0.425) !important;
  color: #fff !important;
  border-color: transparent !important; */
}
.css-bl6clz:hover {
  border: none !important;
}
.css-1g6gooi {
  /* color: #fff !important; */
}

.action_btn {
  float: right;
  margin-top: -20px;
  background: #fff;
  padding: 0 10px;
}

.action_btn .refresh_btn {
  background: #772967;
  border-radius: 5px 5px 0 0;
  padding: 5px 15px;
  width: 25px;
  height: 25px;
  background-image: url(../img/refresh_btn.png);
  background-repeat: no-repeat !important;
  background-position: center !important;
  float: left;
  margin-left: 3px;
  cursor: pointer;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
/* .refresh_btn_img{
     background-image: url(../img/refresh_btn.png) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
} 
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
} */
.action_btn .print_btn {
  background: #fd1f25;
  border-radius: 5px 5px 0 0;
  padding: 5px 15px;
  width: 25px;
  height: 25px;
  background-image: url(../img/print_btn.png);
  background-repeat: no-repeat !important;
  background-position: center !important;
  float: left;
  margin-left: 3px;
  cursor: pointer;
}

.action_btn .excel_btn {
  background: #fe7424;
  border-radius: 5px 5px 0 0;
  padding: 5px 15px;
  width: 25px;
  height: 25px;
  background-image: url(../img/excel_icon.png);
  background-repeat: no-repeat !important;
  background-position: center !important;
  float: left;
  margin-left: 3px;
  cursor: pointer;
}

.action_btn .edvexport {
  background: #1e5152;
  border-radius: 5px 5px 0 0;
  padding: 5px 15px;
  width: 25px;
  height: 25px;
  background-image: url(../img/more_btn.png);
  background-repeat: no-repeat !important;
  background-position: center !important;
  float: left;
  margin-left: 3px;
  cursor: pointer;
}

.cell-wrap-text {
  white-space: normal !important;
}

.rdw-editor-wrapper {
  box-sizing: content-box;
  background-color: #fff;
  color: #1f0019;
  height: 300px;
}

.rdw-editor-main {
  height: auto;
}
/* 
.wrapper {
  position: relative !important;
  top: 0 !important;
  height: 100vh !important;
}

.sidebar,
.off-canvas-sidebar {
  position: fixed !important;
  top: 0 !important;
  height: 100% !important;
  bottom: 0 !important;
  width: 260px !important;
  left: 0 !important;
  z-index: 1030 !important;
  border-right: 1px solid #ddd !important;
}

.sidebar .logo,
.off-canvas-sidebar .logo {
  position: relative;
  padding: 7px 0.8rem;
  z-index: 4;
}

.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
  position: relative;
  height: calc(100vh - 75px);
  overflow: auto;
  width: 260px;
  z-index: 4;
  padding-bottom: 100px;
} */

.widget {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;
  transition: box-shadow 200ms ease,
    -webkit-transform 300ms cubic-bezier(0.34, 2, 0.6, 1);
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease, -webkit-transform 300ms cubic-bezier(0.34, 2, 0.6, 1);
}

.widget .card-header:not([data-background-color]) {
  background-color: transparent;
}

.widget .card-header {
  padding: 15px 15px 0;
  border: 0;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.widget .card-body {
  padding: 15px 15px 10px 15px;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.widget {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;
  transition: box-shadow 200ms ease,
    -webkit-transform 300ms cubic-bezier(0.34, 2, 0.6, 1);
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease, -webkit-transform 300ms cubic-bezier(0.34, 2, 0.6, 1);
}

/* .widget {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
} */

.card-category {
  font-size: 1em;
}
.category,
.card-category {
  text-transform: capitalize;
  font-weight: 400;
  color: #9a9a9a;
  font-size: 0.7142em;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.blacktext {
  color: #000 !important;
}

.terminated {
  background-color: lightcoral !important;
}

/*//////////////////////////////////////////////////////////////////
[ Contact ]*/

.bg-contact100 {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.container-contact100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: rgba(132, 106, 221, 0.9);
}

.wrap-contact100 {
  /* width: 1163px; */
  width: 750px;
  background: #fff;
  color: #000;
  border-radius: 10px;
  overflow: hidden;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 110px 130px 157px 148px; */
}

/*------------------------------------------------------------------
[  ]*/
.contact100-pic {
  width: 310px;
  padding-top: 55px;
}

.contact100-pic img {
  max-width: 100%;
}

/*------------------------------------------------------------------
[  ]*/
.contact100-form {
  width: 390px;
}

.contact100-form-title {
  display: block;
  font-family: Montserrat-ExtraBold;
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: left;
  padding-bottom: 36px;
}

input.input100 {
  height: 50px;
  border-radius: 25px;
  padding: 0 30px 0 50px;
}

input.input100[name="email"] {
  padding: 0 30px 0 54px;
}

textarea.input100 {
  min-height: 150px;
  border-radius: 25px;
  padding: 14px 30px;
}

/*---------------------------------------------*/
.wrap-input100 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 10px;
}

.input100 {
  display: block;
  width: 100%;
  background: #e6e6e6;
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
}

/*------------------------------------------------------------------
[ Focus ]*/
.focus-input100 {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(132, 106, 221, 0.5);
}

.input100:focus + .focus-input100 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 60px 25px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 60px 25px;
    opacity: 0;
  }
}

.symbol-input100 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 30px;
  pointer-events: none;
  color: #aaaaaa;
  font-size: 15px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100 + .symbol-input100 {
  color: #846add;
  padding-left: 22px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-contact100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.contact100-form-btn {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #846add;
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.contact100-form-btn:hover {
  background: #333333;
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 1200px) {
  .contact100-pic {
    width: 33.5%;
  }

  .contact100-form {
    width: 44%;
  }
}

@media (max-width: 992px) {
  .wrap-contact100 {
    /* padding: 110px 80px 157px 90px; */
  }

  .contact100-pic {
    width: 35%;
  }

  .contact100-form {
    width: 55%;
  }
}

@media (max-width: 768px) {
  .wrap-contact100 {
    /* padding: 110px 80px 157px 80px; */
  }

  .contact100-pic {
    display: none;
  }

  .contact100-form {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wrap-contact100 {
    /* padding: 110px 15px 157px 15px; */
  }
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: white;
  border: 1px solid #c80000;
  border-radius: 13px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
  pointer-events: none;

  font-family: Montserrat-Medium;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 13px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

hr {
  margin-top: 18px;
  margin-bottom: 18px;
  border: unset !important;
  border-top: 1px solid red;
}
.hr {
  margin-top: 18px;
  margin-bottom: 18px;
  border-top: 1px solid black !important;
}

.h6LicenceKey {
  color: black;
  font-size: initial;
  font-weight: bold;
  padding: 10px;
}

.red {
  color: red;
}

.ruler {
  margin-top: 18px;
  margin-bottom: 18px;
  border-top: 2px solid #000 !important;
}

.cell-wrap-text {
  white-space: normal !important;
}
.used {
  color: white;
  background-color: red !important;
}
.uploadedimage {
  height: 100px;
  width: 100px;
}

.displaynone {
  display: none !important;
}

.styles_closeButton__20ID4 {
  height: 28px;
  width: 28px;
}

/* start invoice */
@font-face {
  font-family: SourceSansPro;
  src: url(fonts/SourceSansPro-Regular.ttf);
}

.invoice-body .clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.invoice-body a {
  color: #0087C3;
  text-decoration: none;
}

.invoice-body section {
  position: relative;
  width: 19cm;  
  height: 29.7cm; 
  margin: 0 auto; 
  color: #555555;
  background: #FFFFFF; 
  font-family: Arial, sans-serif; 
  font-size: 14px; 
  font-family: SourceSansPro;
}

.invoice-body header {
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #AAAAAA;
}

#logo {
  float: left;
  margin-top: 8px;
}

#logo img {
  height: 70px;
}

#company {
  float: right;
  text-align: right;
}

#details {
  margin-bottom: 35px;
}

#client {
  padding-left: 6px;
  border-left: 6px solid #0087C3;
  float: left;
  width: 60%;
}

#client .to {
  color: #777777;
}

.invoice-body h2.name {
  font-size: 1.4em;
  font-weight: normal;
  margin: 0;
}

#invoice {
  /* float: right; */
  text-align: right;
}

#invoice h1 {
  color: #0087C3;
  font-size: 1.4em;
  line-height: 0.5em;
  font-weight: normal;
  margin: 0  0 10px 0;
}

#invoice .date {
  font-size: 1.1em;
  color: #777777;
}

.invoice-body table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

.invoice-body table th,
.invoice-body table td {
  padding: 0px 5px;
  background: #EEEEEE;
  text-align: right;
  /* border-bottom: 1px solid #FFFFFF; */
}

/* .invoice-body table.taxTable1 {
  margin-top: 100px;
} */

.invoice-body table.taxTable th,
.invoice-body table.taxTable td {
  text-align: left;
}

.invoice-body table.borderTable thead,
.invoice-body table.borderTable tbody {
  border: 1px solid;
}

.invoice-body table th {
  white-space: nowrap;        
  font-weight: normal;
}

.invoice-body table td {
  text-align: right;
}

.invoice-body table td h3{
  color: #57B223;
  font-size: 1.2em;
  font-weight: normal;
  margin: 0 0 0.2em 0;
}

.invoice-body table .no {
  color: #FFFFFF;
  font-size: 1.2em;
  background: #57B223;
}

.invoice-body table .unit {
  background: #DDDDDD;
}

.invoice-body table .total {
  background: #57B223;
  color: #FFFFFF;
}

/* .invoice-body table td.unit,
.invoice-body table td.qty,
.invoice-body table td.total {
  font-size: 1.2em;
} */

/* .invoice-body table tbody tr:last-child td {
  border: none;
} */

.invoice-body table tfoot td {
  padding: 1px 20px;
  background: #FFFFFF;
  border-bottom: none;
  font-size: 1.2em;
  white-space: nowrap; 
  border-top: 1px solid #AAAAAA; 
}

.invoice-body table tfoot tr:first-child td {
  border-top: none; 
}

.invoice-body table tfoot tr:last-child td {
  color: #57B223;
  font-size: 1.4em;
  border-top: 1px solid #57B223; 

}

.invoice-body table tfoot tr td:first-child {
  border: none;
}

#thanks{
  font-size: 2em;
  margin-bottom: 50px;
}

#notices{
  padding-left: 6px;
  border-left: 6px solid #0087C3;  
}

#notices .notice {
  font-size: 1.2em;
}

.invoice-body footer {
  color: #777777;
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #AAAAAA;
  padding: 8px 0;
  text-align: center;
}

/* end invoice */


/* start loader */

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid blue;
  border-right: 16px solid green;
  border-bottom: 16px solid red;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.overlay {
  /* background: #e9e9e9;  */
  position: absolute;  
  top: 0;      
  right: 0; 
  bottom: 0;
  left: 0;
  /* opacity: 0.5; */
}

/* end loader */

.headerText {
  margin-left: 10%;
}

.filepond--wrapper {
  opacity: 0.99;
}

.keys{
  color:black;
  font-weight: bolder;
  align-content: right;
  font-size: x-large;
}
.blue_font_bold{
  color:navy;
  font-weight:bold;
}
.green_font_bold{
  color:green;
  font-weight:bold;
}

.react-date-picker__wrapper{
  
}